<template>
  <v-row>
    <v-col class="pa-0 ma-0" cols="12" data-test="money-network-logo">
      <v-sheet>
        <v-row class="d-flex align-center">
          <v-col
            v-if="
              getSelectedPayoutSrc == 'PREPAID' ||
              getSelectedPayoutSrc == 'MONEYNETWORK'
            "
            class="ml-6"
            cols="5"
          >
            <MNLogo class="money-network-logo" />
          </v-col>
          <v-col v-if="getSelectedPayoutSrc == 'US_DEBIT'" class="d-flex">
            <USDbitLogo class="usdebit-network-logo" />
          </v-col>
          <v-col
            v-if="
              getSelectedPayoutSrc == 'PREPAID' ||
              getSelectedPayoutSrc == 'MONEYNETWORK'
            "
            :class="smAndDown ? 'ml-12' : 'ml-7'"
            class="mt-3"
            cols="4"
          >
            <div class="d-flex pb-2">
              {{ $t("NewMoneyNetworkForm.moneyNetwork") }}
            </div>
          </v-col>
          <v-col
            v-if="getSelectedPayoutSrc == 'US_DEBIT'"
            class="mt-6 pl-6"
            cols="12"
          >
            <div class="d-flex pb-2">
              {{ $t("NewMoneyNetworkForm.usdebitcardEnrollHeading") }}
            </div>
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import MNLogo from "@/assets/img/payout-methods/moneynetwork-newLogo.svg?inline";
import USDbitLogo from "@/assets/img/usdebitcardlogo.svg?inline";
import { usePayoutStore } from "@/stores/payout";
import { useDisplay } from "vuetify";

export default {
  name: "MoneyNetworkLogo",

  components: {
    MNLogo,
    USDbitLogo,
  },

  setup() {
    const payoutStore = usePayoutStore();
    const { smAndDown } = useDisplay();
    return {
      payoutStore,
      smAndDown,
    };
  },
  computed: {
    getSelectedPayoutSrc() {
      return this.payoutStore.getPayoutTypeSelectedSource;
    },
  },
};
</script>
<style scoped>
.money-network-logo {
  fill: #ffffff;
  height: 43px;
}

.usdebit-network-logo {
  /* fill: #ffffff; */
  height: 6rem;
  top: 1rem;
  position: relative;
}
</style>
